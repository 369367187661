@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDz8Z1xlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJfedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLGT9Z1xlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLEj6Z1xlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLCz7Z1xlEA.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  background: #f9f9f9;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body ul,
body ol,
body p {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
  color: #2bbbad;
}
body a:hover {
  color: #219287;
}
body p {
  color: #666;
  font-size: 13px;
  line-height: 22px;
}
body ul,
body ol {
  color: #666;
  font-size: 13px;
  line-height: 22px;
  padding-left: 18px;
}
body .list-unstyled {
  padding: 0;
}
body .btn {
  margin: 0;
  border-radius: 40px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 10px 30px;
  color: #fff !important;
  transition: 0.3s;
}
body .btn.btn-sm {
  padding: 5px 22px;
  font-size: 10px;
}
body .btn.btn-100 {
  width: 100%;
}
body .m-t-5 {
  margin-top: 5px;
}
body .m-t-10 {
  margin-top: 10px;
}
body .m-t-15 {
  margin-top: 15px;
}
body .m-t-20 {
  margin-top: 20px;
}
body .m-t-25 {
  margin-top: 25px;
}
body .m-t-30 {
  margin-top: 30px;
}
body .m-b-5 {
  margin-bottom: 5px;
}
body .m-b-10 {
  margin-bottom: 10px;
}
body .m-b-15 {
  margin-bottom: 15px;
}
body .m-b-20 {
  margin-bottom: 20px;
}
body .m-b-25 {
  margin-bottom: 25px;
}
body .m-b-30 {
  margin-bottom: 30px;
}
body .p-t {
  padding-top: 15px;
}
body .p-t-5 {
  padding-top: 5px;
}
body .p-t-10 {
  padding-top: 10px;
}
body .p-t-15 {
  padding-top: 15px;
}
body .p-t-20 {
  padding-top: 20px;
}
body .p-t-25 {
  padding-top: 25px;
}
body .p-t-30 {
  padding-top: 30px;
}
body .p-b-5 {
  padding-bottom: 5px;
}
body .p-b-10 {
  padding-bottom: 10px;
}
body .p-b-15 {
  padding-bottom: 15px;
}
body .p-b-20 {
  padding-bottom: 20px;
}
body .p-b-25 {
  padding-bottom: 25px;
}
body .p-b-30 {
  padding-bottom: 30px;
}
section.logowanie-section-content .content-flexbox {
  min-height: 100vh;
  display: flex;
}
section.logowanie-section-content .content-flexbox .flexbox-service {
  width: 40%;
  padding: 100px 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
section.logowanie-section-content .content-flexbox .flexbox-service .logo {
  letter-spacing: 1px;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
  color: #555;
}
section.logowanie-section-content .content-flexbox .flexbox-service .logo span {
  color: #2bbbad;
  font-size: 35px;
  transform: translateY(12px);
}
section.logowanie-section-content .content-flexbox .flexbox-service .logo img {
  height: 40px;
}
section.logowanie-section-content .content-flexbox .flexbox-service .form-service {
  margin-top: 20px !important;
}
section.logowanie-section-content .content-flexbox .flexbox-service .btn {
  margin-top: 60px;
  width: 100%;
}
section.logowanie-section-content .content-flexbox .flexbox-service .forgot {
  margin-top: 30px;
  display: block;
  font-size: 11px;
  color: #2bbbad;
  cursor: pointer;
}
section.logowanie-section-content .content-flexbox .flexbox-service .buttons-wrapper {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
section.logowanie-section-content .content-flexbox .flexbox-service .buttons-wrapper .btn {
  margin: 5px;
}
section.logowanie-section-content .content-flexbox .flexbox-cta {
  width: 60%;
  overflow: hidden;
}
section.logowanie-section-content .content-flexbox .flexbox-cta iframe {
  border: none;
  width: 100%;
  height: 100%;
}
h1 {
  font-size: 28px;
  font-weight: 400;
  color: #444;
  margin: 20px 0;
}
h2 {
  font-size: 23px;
  font-weight: 400;
  color: #444;
  margin: 20px 0;
}
h3 {
  font-size: 18px;
  font-weight: 400;
  color: #444;
  margin: 20px 0;
}
.body-wrapper {
  display: flex;
  transition: all 0.7s ease;
}
.body-wrapper .inner-wrapper {
  padding-top: 65px;
  padding-bottom: 57px;
  transition: all 0.7s ease;
  position: relative;
  min-width: calc(100% - 260px);
  max-width: calc(100% - 260px);
}
.body-wrapper .inner-wrapper .inner-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.body-wrapper .inner-wrapper .basic-box {
  padding: 0 20px 20px 20px;
  background: #fff;
  position: relative;
  border: 1px solid #e7e7e7;
}
.body-wrapper .inner-wrapper .basic-box .box-heading {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.body-wrapper .inner-wrapper .basic-box .box-heading h1,
.body-wrapper .inner-wrapper .basic-box .box-heading h2,
.body-wrapper .inner-wrapper .basic-box .box-heading h3 {
  padding-right: 30px;
}
.body-wrapper .inner-wrapper .basic-box .box-heading .dropdown {
  margin-top: 20px;
}
.body-wrapper .inner-wrapper .basic-box .box-heading .dropdown .dropdown-toggle {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
}
.body-wrapper .inner-wrapper .basic-box .box-heading .dropdown .dropdown-toggle img {
  width: 15px;
  transform: rotate(90deg);
}
.body-wrapper .inner-wrapper .basic-box .box-heading.collapse-button {
  pointer-events: none;
}
.body-wrapper .inner-wrapper .basic-box .box-heading.collapse-button .dropdown {
  display: none;
}
.body-wrapper .inner-wrapper .basic-box .box-heading.collapse-button.active {
  pointer-events: auto;
}
.body-wrapper .inner-wrapper .basic-box .box-heading.collapse-button.active .dropdown {
  display: block;
}
.body-wrapper .inner-wrapper .basic-box > * {
  margin-top: 20px;
}
.body-wrapper .inner-wrapper .basic-box h1,
.body-wrapper .inner-wrapper .basic-box h2,
.body-wrapper .inner-wrapper .basic-box h3 {
  margin: 20px 0 0 0;
}
.body-wrapper .inner-wrapper .basic-box .collapse {
  margin-top: 0;
}
.body-wrapper .inner-wrapper .container-fluid > *,
.body-wrapper .inner-wrapper .container > * {
  margin-top: 20px;
}
.body-wrapper .inner-wrapper.active {
  padding-left: 0;
  margin-left: 0;
  min-width: 100%;
  max-width: 100%;
}
.nav-left .button {
  cursor: pointer;
  width: auto;
  margin: 0 auto;
  transition: all 0.7s ease;
}
.nav-left .button .bar {
  display: block;
  height: 2px;
  width: 20px;
  border-radius: 20%;
  background-color: #555;
  margin-bottom: 3px;
  transition: all 0.7s ease;
}
.nav-left .button .bar:last-child {
  margin-bottom: 0;
}
.header-sidebar {
  position: fixed;
  z-index: 334;
  top: 0;
  left: -260px;
  min-height: 100vh;
  min-width: 260px;
  max-width: 260px;
  background: #fff;
  border-right: 1px solid #e7e7e7;
  transition: all 0.7s;
}
.header-sidebar .nav-left-sm {
  display: none;
  position: absolute;
  top: 7px;
  padding: 15px;
  right: 0;
  z-index: 555;
  cursor: pointer;
}
.header-sidebar .nav-left-sm .button {
  display: inline-block;
}
.header-sidebar .nav-left-sm .button .bar.top {
  transform: translateY(5px) rotateZ(45deg);
}
.header-sidebar .nav-left-sm .button .bar.middle {
  width: 0;
}
.header-sidebar .nav-left-sm .button .bar.bottom {
  transform: translateY(-5px) rotateZ(-45deg);
}
.header-sidebar .logo {
  position: fixed;
  z-index: 444;
  left: -260px;
  top: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 700;
  height: 65px;
  padding: 0 15px;
  background: #fff;
  transition: all 0.7s;
  color: #555;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}
.header-sidebar .logo span {
  color: #2bbbad;
  font-size: 25px;
  transform: translateY(6px);
}
.header-sidebar .logo img {
  height: 30px;
}
.header-sidebar .sidebar-navigation {
  margin-top: 65px;
  padding-top: 30px;
}
.header-sidebar .sidebar-navigation ul li {
  position: relative;
  transition: all 0.7s;
}
.header-sidebar .sidebar-navigation ul li a {
  padding: 15px 35px 15px 25px;
  font-size: 14px;
  color: #444;
  opacity: 0.9;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 500;
  z-index: 111;
  transition: all 0.7s;
}
.header-sidebar .sidebar-navigation ul li a img {
  width: 20px;
  margin-right: 20px;
}
.header-sidebar .sidebar-navigation ul li a:hover {
  color: #000;
}
.header-sidebar .sidebar-navigation ul li.has-child {
  display: flex;
  align-items: center;
}
.header-sidebar .sidebar-navigation ul li.has-child.active:after {
  transform: rotate(90deg);
}
.header-sidebar .sidebar-navigation ul li.has-child:after {
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  right: 20px;
  background: url('../img/icons/nav/chevron.svg') center;
  background-size: cover;
  transition: all 0.7s;
}
.header-sidebar .sidebar-navigation ul li.child {
  margin-top: 0;
}
.header-sidebar .sidebar-navigation ul li.child ul li a {
  padding: 6px 65px;
  display: block;
  font-size: 12px;
  color: #555;
}
.header-sidebar .sidebar-navigation ul li:last-child {
  margin-bottom: 0;
}
.header-sidebar.active {
  left: 0;
  position: relative;
}
.header-sidebar.active .logo {
  left: 0;
}
nav.section-header {
  height: 65px;
  background: #fff;
  position: fixed;
  z-index: 333;
  padding-left: 260px;
  border-bottom: 1px solid #e7e7e7;
  top: 0;
  width: 100%;
  transition: all 0.7s;
}
nav.section-header.active {
  padding-left: 0px;
  width: 100%;
}
nav.section-header .container-fluid {
  height: 100%;
}
nav.section-header .header-flexbox {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-inner-wrapper > * {
  margin-right: 30px;
}
nav.section-header .header-flexbox .flexbox-inner-wrapper > *:last-child {
  margin-right: 0;
}
nav.section-header .nav-back {
  padding: 5px;
  margin-left: -5px;
}
nav.section-header .nav-back img {
  height: 15px;
}
nav.section-header .header-icon {
  position: relative;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}
nav.section-header .header-icon img {
  height: 20px;
}
nav.section-header .header-icon .badge {
  position: absolute;
  top: -8px;
  right: -8px;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #cd270c;
  z-index: 111;
  font-size: 9px;
  font-weight: 300;
  color: #fff;
}
nav.section-header .dropdown .btn-avatar {
  padding: 0;
  border: none;
  background: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
nav.section-header .dropdown .btn-avatar .avatar-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}
nav.section-header .dropdown .btn-avatar .avatar-img img {
  width: 100%;
}
nav.section-header .dropdown .btn-avatar .avatar-name {
  margin-left: 15px;
  font-size: 12px;
  color: #444;
}
nav.section-header .dropdown .btn-avatar:after {
  content: none;
}
nav.section-header .search-engine {
  display: flex;
  align-items: center;
}
nav.section-header .search-engine input {
  padding: 5px 15px;
  margin: 0;
  border-radius: 30px 0 0 30px;
  border: 1px solid #2bbbad;
  border-right: none;
  height: 20px;
  font-size: 12px;
  width: 160px;
  transition: 0.7s;
}
nav.section-header .search-engine input:focus {
  border: 1px solid #2bbbad;
  border-right: none;
  box-shadow: none;
  width: 180px;
}
nav.section-header .search-engine input::placeholder {
  color: #bbb;
}
nav.section-header .search-engine .btn-search {
  padding: 3px 10px;
  border-radius: 0 30px 30px 0;
  border: 1px solid #2bbbad;
  border-left: none;
  background: none;
  cursor: pointer;
}
nav.section-header .search-engine .btn-search img {
  width: 18px;
}
.breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background: none;
}
.breadcrumb li {
  font-size: 11px;
}
.breadcrumb li a {
  color: #2bbbad;
}
.breadcrumb li.active {
  color: #888;
}
.breadcrumb li:before {
  color: #ddd !important;
}
.buttons-fixed {
  position: fixed;
  z-index: 222;
  padding: 10px;
  right: -30px;
  bottom: 5px;
  transition: all 0.3s;
}
.buttons-fixed .fixed-background {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 150%;
  width: 200%;
  border-radius: 20px 0 0 20px;
  background: rgba(255, 255, 255, 0.85);
  filter: blur(20px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.buttons-fixed > * {
  margin-bottom: 15px;
}
.buttons-fixed > *:last-child {
  margin-bottom: 0;
}
.buttons-fixed .btn-circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  position: relative;
}
.buttons-fixed .btn-circle span {
  position: absolute;
  right: 100%;
  padding-right: 10px;
  color: #444;
  font-size: 12px;
  transition: 0.3s;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
}
.buttons-fixed .btn-circle img {
  height: 17px;
  opacity: 0;
  transition: 0.3s;
}
.buttons-fixed .btn-circle:active span,
.buttons-fixed .btn-circle:focus span {
  visibility: hidden;
  opacity: 0;
}
.buttons-fixed .dropdown.show .btn-circle,
.buttons-fixed .dropup.show .btn-circle {
  border-radius: 2px 50% 50% 50%;
}
.buttons-fixed .dropdown .dropdown-menu,
.buttons-fixed .dropup .dropdown-menu {
  right: 100% !important;
  bottom: 100% !important;
  top: auto !important;
  left: auto !important;
  transform: none !important;
}
.buttons-fixed.body-bottom {
  bottom: 47px;
}
.buttons-fixed.active {
  right: 0;
}
.buttons-fixed.active .btn-circle img {
  opacity: 1;
}
.buttons-fixed.active .btn-circle span {
  visibility: visible;
  opacity: 1;
}
.buttons-fixed.active .fixed-background {
  opacity: 1;
  visibility: visible;
}
/* Content elements */
.alert {
  margin: 0;
  font-size: 14px;
  border-radius: 0;
}
.alert.alert-absolute {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.nav-pills {
  align-items: center;
}
.nav-pills .nav-heading {
  margin-right: 20px;
}
.nav-pills .nav-item {
  margin-right: 20px;
}
.nav-pills .nav-item a {
  background: #e7e7e7;
}
.table-responsive table {
  margin-bottom: 0;
  font-size: 11px !important;
}
.table-responsive table tr {
  font-size: 12px;
}
.table-responsive table tr th {
  padding: 14px;
  font-size: 12px;
  font-weight: 500;
}
.table-responsive table tr th a {
  color: #fff;
  opacity: 0.7;
  cursor: pointer;
}
.table-responsive table tr th.active a {
  font-weight: 700;
  position: relative;
  opacity: 1;
}
.table-responsive table tr th.active a:after {
  content: '';
  height: 6px;
  width: 6px;
  top: 50%;
  transform: translateY(-50%);
  right: -10px;
  background: url('../img/icons/table/down-triangle.svg') center;
  background-size: cover;
  position: absolute;
}
.table-responsive table tr th.active.up a:after {
  transform: translateY(-50%) rotate(180deg);
}
.table-responsive table tr td {
  padding: 14px;
  vertical-align: middle;
}
.table-responsive table tr td input {
  margin: 0;
  padding: 0 0 0 5px;
  height: 1.3rem;
  font-size: 12px;
  font-weight: 300;
}
.table-responsive table tr td .select2-selection--single,
.table-responsive table tr td select {
  height: 1.3rem !important;
  font-size: 12px;
}
.table-responsive table tr td a,
.table-responsive table tr td button {
  color: #2bbbad;
  font-size: 12px;
  padding: 0;
  margin: 0;
  outline: none;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.table-responsive table tr td a img,
.table-responsive table tr td button img {
  height: 15px;
}
.table-responsive table tr td .checkbox-service {
  margin: 0;
}
.table-responsive table tr td .checkbox-service input {
  height: auto;
}
.table-responsive table tr td .checkbox-service .service-inner:before {
  margin: 0;
}
.table-responsive table tr .d-flex {
  justify-content: flex-start;
  align-items: center;
}
.table-responsive table tr .d-flex > * {
  margin-right: 20px;
}
.table-responsive table tr .d-flex > *:last-child {
  margin-right: 0;
}
.table-responsive table tr.alert-dot {
  background: #f9f1f1 !important;
}
.table-responsive table tr.alert-dot td:last-child {
  position: relative;
}
.table-responsive table tr.alert-dot td:last-child:before {
  content: '';
  height: 7px;
  width: 7px;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  background: #cd270c;
  right: 5px;
}
.table-responsive table tr.success-dot {
  background: #f6f9f1 !important;
}
.table-responsive table tr.success-dot td:last-child {
  position: relative;
}
.table-responsive table tr.success-dot td:last-child:before {
  content: '';
  height: 7px;
  width: 7px;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  background: #74a517;
  right: 5px;
}
.table-responsive table tr.warning-dot {
  background: #f9f9f1 !important;
}
.table-responsive table tr.warning-dot td:last-child {
  position: relative;
}
.table-responsive table tr.warning-dot td:last-child:before {
  content: '';
  height: 7px;
  width: 7px;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  background: #e1c402;
  right: 5px;
}
.table-responsive table tr.info-dot {
  background: #e8edf7 !important;
}
.table-responsive table tr.info-dot td:last-child {
  position: relative;
}
.table-responsive table tr.info-dot td:last-child:before {
  content: '';
  height: 7px;
  width: 7px;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  background: #37a1ec;
  right: 5px;
}
.table-responsive table tr:hover {
  background: #f9f9f9;
}
.table-responsive table tr:first-child {
  background: #777;
  color: #fff;
}
.pagination-nav-wrapper {
  padding: 15px;
  background: #f9f9f9;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pagination-nav-wrapper .pagination-view {
  display: flex;
  align-items: center;
}
.pagination-nav-wrapper .pagination-view p {
  font-size: 12px;
  color: #777;
}
.pagination-nav-wrapper .pagination-view button,
.pagination-nav-wrapper .pagination-view a {
  padding: 0;
  border: none;
  background: none;
  font-size: 12px;
  cursor: pointer;
}
.pagination-nav-wrapper .pagination-view button.active,
.pagination-nav-wrapper .pagination-view a.active {
  font-weight: 700;
  color: #2bbbad;
}
.pagination-nav-wrapper .pagination-view .form-service {
  margin-right: 5px;
}
.pagination-nav-wrapper .pagination-view input {
  width: 60px;
}
.pagination-nav-wrapper .pagination-view > * {
  margin-right: 10px;
}
.pagination-nav-wrapper .pagination-view > *:last-child {
  margin-right: 0;
}
.pagination-nav-wrapper .pagination-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.pagination-nav-wrapper .pagination-nav li a {
  display: block;
  padding: 10px;
  font-size: 13px;
  color: #666;
  transition: all 0.3s;
}
.pagination-nav-wrapper .pagination-nav li a:hover {
  color: #2bbbad;
}
.pagination-nav-wrapper .pagination-nav li.active a {
  font-weight: 700;
  color: #2bbbad;
}
.pagination-nav-wrapper .pagination-nav li:first-child a,
.pagination-nav-wrapper .pagination-nav li:last-child a {
  font-size: 12px;
}
.cards-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.cards-grid .grid-service {
  display: block;
  background: #fff;
  border: 1px solid #e7e7e7;
  position: relative;
}
.cards-grid .grid-service .badge {
  position: absolute;
  top: 15px;
  left: 15px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #cd270c;
  z-index: 111;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.cards-grid .grid-service .service-image-wrapper {
  width: 100%;
  padding-top: 65%;
  position: relative;
  overflow: hidden;
}
.cards-grid .grid-service .service-image-wrapper .service-image {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.cards-grid .grid-service img {
  width: 100%;
}
.cards-grid .grid-service .service-footer {
  padding: 15px;
}
.cards-grid .grid-service .service-footer p {
  font-size: 16px;
  color: #444;
}
.form-service {
  margin-top: 0 !important;
}
.form-service label {
  margin-top: 20px;
  font-size: 12px;
  color: #777;
  font-weight: 400;
}
.form-service input,
.form-service select {
  height: 35px;
  font-size: 14px;
  color: #444;
}
.form-service select {
  cursor: pointer;
}
.form-service .select2 {
  width: 100% !important;
}
.form-service .select2-selection--single {
  display: block;
  height: 35px !important;
  font-size: 14px;
  color: #444 !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
}
.form-service .select2-selection__rendered {
  height: 100%;
  display: flex !important;
  align-items: center;
}
.form-service .select2-selection__arrow {
  height: 100% !important;
}
.form-service .select2-search__field {
  font-size: 14px !important;
  color: #444 !important;
}
.form-service textarea {
  font-size: 14px;
  color: #444;
}
.form-service h1,
.form-service h2,
.form-service h3 {
  margin: 0 !important;
}
.form-service .info {
  margin-top: 20px;
}
.form-service .info p {
  font-size: 11px;
  color: #777;
}
.form-service .btn {
  margin-top: 20px;
}
.checkbox-service {
  margin-top: 20px;
  margin-bottom: 0;
  cursor: pointer;
  width: 100%;
  display: block;
}
.checkbox-service .service-inner {
  display: flex;
}
.checkbox-service .service-inner .name {
  font-weight: 400;
  text-align: left;
  font-size: 13px;
}
.checkbox-service .service-inner:before {
  content: '';
  display: block;
  margin-top: 3px;
  margin-right: 12px;
  height: 12px;
  min-width: 12px;
  max-width: 12px;
  background: url('../img/icons/success.svg') center;
  transition: all 0.4s;
}
.checkbox-service input {
  visibility: hidden;
  position: absolute;
}
.checkbox-service > input:checked + .service-inner:before {
  background: url('../img/icons/success-fill.svg') center;
}
.dropdown .dropdown-toggle:after,
.dropup .dropdown-toggle:after {
  content: none;
}
.dropdown .dropdown-menu,
.dropup .dropdown-menu {
  padding: 10px;
  border: 1px solid #e7e7e7;
}
.dropdown .dropdown-menu a,
.dropup .dropdown-menu a,
.dropdown .dropdown-menu button,
.dropup .dropdown-menu button {
  transition: all 0.5s;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}
.dropdown .dropdown-menu a:hover,
.dropup .dropdown-menu a:hover,
.dropdown .dropdown-menu button:hover,
.dropup .dropdown-menu button:hover {
  background: #219287;
  color: #fff;
}
.dropdown .dropdown-menu.dropdown-menu-right a,
.dropup .dropdown-menu.dropdown-menu-right a,
.dropdown .dropdown-menu.dropdown-menu-right button,
.dropup .dropdown-menu.dropdown-menu-right button {
  text-align: right;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.9);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 500px;
  max-width: 100%;
  border: 1px solid #e7e7e7;
  padding: 20px;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 12px;
  right: 9px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  margin-top: 0;
  margin-bottom: 10px;
  padding-right: 40px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dm-uploader {
  border: 0.25rem dashed #e7e7e7;
}
.custom-file-label::after {
  content: "Przeglądaj";
}
.dm-uploader {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dm-uploader .btn {
  position: relative;
  overflow: hidden;
}
.dm-uploader .btn input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  border: solid transparent;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.sticky-search-engine {
  position: sticky;
  top: 64px;
  z-index: 222;
  padding: 20px;
  background: #fff;
  border: 1px solid #e7e7e7;
}
.sticky-search-engine label {
  margin-top: 0;
}
.sticky-search-engine .search-engine-inner {
  display: flex;
  align-items: center;
}
.sticky-search-engine .btn {
  margin-left: 20px;
}
/* Content elements */
section.section-footer {
  padding: 10px 0;
  transition: all 0.7s;
  position: relative;
  margin-top: -43px;
  border-top: 1px solid #e7e7e7;
  background: #fff;
}
section.section-footer p {
  font-size: 0.7rem;
  font-weight: 300;
  color: #919191;
  text-align: right;
}
section.section-footer.active {
  margin-left: 260px;
}
@media (max-width: 1199.98px) {
  .pagination-nav-wrapper {
    flex-direction: column;
  }
  .pagination-nav-wrapper .pagination-view:first-child {
    margin-top: 20px;
    order: 5;
  }
  .pagination-nav-wrapper .pagination-view:last-child {
    margin-top: 20px;
  }
  .pagination-nav-wrapper .pagination-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .pagination-nav-wrapper .pagination-nav li a {
    display: block;
    padding: 10px;
    font-size: 13px;
    color: #666;
    transition: all 0.3s;
  }
  .pagination-nav-wrapper .pagination-nav li a:hover {
    color: #2bbbad;
  }
  .pagination-nav-wrapper .pagination-nav li.active a {
    font-weight: 700;
    color: #2bbbad;
  }
  .pagination-nav-wrapper .pagination-nav li:first-child a,
  .pagination-nav-wrapper .pagination-nav li:last-child a {
    font-size: 12px;
  }
  .cards-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 991.98px) {
  .cards-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  section.logowanie-section-content .content-flexbox .flexbox-service {
    width: 50%;
  }
  section.logowanie-section-content .content-flexbox .flexbox-cta {
    width: 50%;
  }
}
@media (max-width: 767.98px) {
  .body-wrapper {
    display: block;
  }
  .body-wrapper .inner-wrapper {
    min-width: 100%;
    max-width: 100%;
  }
  .header-sidebar {
    left: 0;
    position: absolute;
    min-width: 100%;
    max-width: 100%;
  }
  .header-sidebar .nav-left-sm {
    display: block;
  }
  .header-sidebar .logo {
    position: relative;
    left: auto;
    width: auto;
    background: none;
  }
  .header-sidebar .sidebar-navigation {
    margin-top: 0;
  }
  .header-sidebar .sidebar-navigation ul li a {
    padding: 15px 35px 15px 15px;
  }
  .header-sidebar .sidebar-navigation ul li a img {
    margin-right: 15px;
  }
  .header-sidebar .sidebar-navigation ul li.child ul li a {
    padding: 6px 50px;
  }
  .header-sidebar.active {
    position: absolute;
    left: -100%;
  }
  nav.section-header {
    padding-left: 0;
  }
  nav.section-header .dropdown .btn-avatar .avatar-name {
    display: none;
  }
  section.section-footer p {
    text-align: center;
  }
  section.section-footer.active {
    margin-left: 0;
  }
}
@media (max-width: 575.98px) {
  section.logowanie-section-content .content-flexbox {
    flex-direction: column;
  }
  section.logowanie-section-content .content-flexbox .flexbox-service {
    width: 100%;
    min-height: 100vh;
  }
  section.logowanie-section-content .content-flexbox .flexbox-cta {
    display: none;
  }
  .buttons-fixed {
    right: 0;
  }
  .buttons-fixed .fixed-background {
    display: none;
  }
  .buttons-fixed .btn-circle {
    height: 35px;
    width: 35px;
  }
  .buttons-fixed .btn-circle span {
    display: none;
  }
  .buttons-fixed .btn-circle img {
    opacity: 1;
  }
  .buttons-fixed.body-bottom {
    bottom: 47px;
  }
}
@media (max-width: 400px) {
  .cards-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
